.fixed-top{
    top: -8px;
}


#nopadding{
    padding: 0px;
    margin-top: 82px;
    
}


#ham{
    color: white;
}

#dropdown{
    background-color: white;
    width: 200px;
}

#dropdowndriver{
    color: black
}

#dropdwonitem{
    color: black

}

#toggleStyle{
  
    background: rgb(188, 75, 94)
}




.LogoStyle{
    height: 50px;
    width: 150px;
    margin-right: -40px;
    cursor: pointer;
    filter: brightness(250%);
  
}
.navbar-brand {
    position: absolute;
    top: 15px;
    left: 30px;
    background-color: white;
    width: 40px;
    height: 40px;
    color: red;
    text-align: center;
    border-radius: 50%;
    line-height: 30px;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;


}

.nav-item{
    margin-right:30px ;
    
}

#clicking{
    cursor: pointer;
}

.Main-Heading {
    height: 15px;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
}

.cancelclass {
    background: #ffffff;
    color: #192f60;
    border: 1px solid rgb(249, 244, 244);
    width: 80px;
    border-radius: 3px;
    height: 30px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-family: Poppins;
    font-weight: bold;
    margin-left: 226px;

}

.cancelclass:hover {
    background: #198754;
    /* background: pink; */
}

.my-facebook-button-class {
    width: 300px;
    height: 40px;
    padding: 5px;
    border-radius: 6px;
    border: solid 2px #c7c7c7;
    background-color: #ffffff;
    opacity: 0.8;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #192f60;
    font-weight: bold;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    box-shadow: none;

}

.my-facebook-button-class .fa-facebook {
    margin-right: 15px;
    background: #192f60;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    padding: 2px;
    color: white;
    line-height: 24px;
}

.bottom-text {
    color: #192f60;
    text-align: center;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-family: Poppins;
}



.SignUpclass {
    background: none;
    color: red;
    border: none;
}


.passwordHandlers{
    background: none;
    color:  #192f60;
    border: none;
    font-size: 15px;
    font-weight: bold;
    margin-top: 10px;
}

.Resethandler{
    background: #ffffff;
    color: #192f60;
    border: 1px solid rgb(249, 244, 244);
    width: 80px;
    border-radius: 3px;
    height: 30px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-family: Poppins;
    font-weight: bold;
   

}





@media (max-width:991px) {
           

    .navbar-brand {

        z-index: -1;
    }

    .nav-item {
        margin-left: -50px;
    }

    .d-flex {
        margin-left: -50px;
    }

    .yes-padding {
        top: 250px;


    }
}


@media (max-width:576px){
    #nopadding{
        overflow: hidden;/* Adjust container width for small screens */
      }
}


