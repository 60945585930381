.whatstyle {
    position: relative;
    top: -16px;
}


.imgstyles {
    height: 610px;
    width: 100%;
}

.videostyle{
    height: 610px;
    width: 100%;
    background-color: black;
}
.lordshiva{
    position: absolute;
    right: 100px;
    top: 70px;
    filter: brightness(20%);
    z-index: -1;
}


.whatsession {

    /*    
    background: url('../Assets/hqdefaulone.jpeg');     */
    overflow: hidden;

    transition: transform 0.5s ease;
    /* Transition for transform property */

}


.whatsessionexplan {
    position: absolute;
    top: 70px;
    left: 151px;



}


.whating{
    color: white;
    font-size: 18px;
}

#whatpart {
    color: white;
}



hr {
    margin-top: 9px;
    color: white;
    width: 900px;

}

@media (max-width:1060px) {
    hr {
        width: 500px;
    }
}

@media (max-width:650px) {
    hr {
        width: 300px;
    }

    .whatsessionexplan {

        left: 120px;



    }

}


@media(max-width: 430px) {
    .whatsessionexplan {
        left: 20px;
    }


    .imgstyles {
        height: 400px;
        width: 100%;


    }



}


#WhatSection{
    background-color: black;
    /* height: 300px; */
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
}

#WhatSections{
   background-color: black;
    /* height: 300px; */
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 30px;
    
   
}


.imgageheightlight{
    height: 240px;
    border-radius: 10px;
    
}




.explaningpartfirst{
    color: rgb(26, 221, 49);
    font-size: 20px;

}





.explaningpartfirsts{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    
    color: pink;
    
    
  }

 

  .FounderBoxStyle{
    min-width: 250px;
    max-width: 500px;
    margin: 10px;
    height: 220px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }
  
.FounderImage{
    position: relative;
    left: 50px;
}

.Foundertitle{
     position: relative;
     left: 26px;
     top: 3px;
     color: rgb(7, 7, 22);
     font-size: 20px;
     font-weight: bold;
     
    
}

.Founder{
    border-radius: 5px;
    filter: brightness(150%);
}

.text-contect{
    border-right: 1px solid pink;
   position: relative;
   top: 8px;
   left:10px;
  
}
  
  /* .explaningparts{
    font-family: Poppins;
    color: #192f60;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
  } */


  .FounderArticle{
     color: rgb(71, 71, 91);
     position: relative;
     top: 20px;
     font-weight: bold;
     font-family: Arial, Helvetica, sans-serif;
  }

@media(max-width:420px){
    .imgageheightlight{
        width: 250px;
    }
}

@media(max-width:770px){
    .imgageheightlight{
        width: 290px;
    }
}

@media(max-width:570px){
    #left_part{
        margin-bottom: 11px;
    }
}


.whatsession.loaded {
    transform: scale(1);
    /* Apply transform when the loaded state is true */


}

@keyframes slideIn {
    from {
        opacity: 0;
        /* transform: translateY(-100%); */
        transform: scale(1);
        /* transform: rotateZ(0deg); */
        /* transform: scaleX(1); */
        /* transform: rotate(0deg); */
    }

    to {
        opacity: 1;
        /* transform: translateY(0); */
        transform: scale(1.13);
        filter: brightness(60%);
        /* transform: rotateZ(45deg); */
        /* transform: scaleX(0.5); */
        /* transform: rotate(360deg) */
    }
}

.whatsession.loaded img {
    animation: slideIn 3s ease-in-out;
    /* animation-delay: 1sec;
    animation-iteration-count: infinite; */
    /* animation: slideIn 3s linear 4s infinite; */




}


@media (max-width: 1026px){
  .videostyle{
      height: 400px;
      margin-bottom: -6px;
        
  }
 


}

@media (max-width: 500px){
    .videostyle{
        height: 250px
    }


}

@media (max-width: 1600px){
    .videostyle{
        margin-bottom: -6px;
    }
}


@media (max-width: 1199px){
    .FounderImage{
       left: 16px;
    }
}


@media (max-width:995px){
    .FounderImage{
        left: 50px;
    }
}

@media (max-width:630px){
    .FounderImage{
         left: -5px;
    }

    .FounderBoxStyle{
        width: 400px;
    }
}

@media( max-width:435px){
    .FounderImage{
        left: -30px;
    }

    .FounderBoxStyle{
        width: 350px;
    }
}

@media(max-width:385px){
    .FounderImage{
        left: -10px;
        
    }

   .Founder{
        max-width: 158px;
   }

   .FounderBoxStyle{

    max-width: 319px;
    
    
   }

}

@media(max-width:330px){
    .FounderBoxStyle{

        max-width: 280px;
        height: 200px;
        
       }

       .Founder{
        max-width: 140px;
       }
  
       .FounderArticle{
     
        top: -5px;
        line-height: 20px;
        
     }
}


@media(max-width: 330px){
    .imgageheightlight{
        width: 260px;
    }
}


